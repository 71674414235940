.loginView {
  background-image: url('../../../assets/backgrounds/login-background.png');
  background-size: cover;
  background-color: #343434;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  background-color: transparent;
  border-radius: 5px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 495px;
  height: 597px;
  position: relative;
  overflow: hidden;
}

.subtitle {
  color: #AEAEA6;
  font-family: Roboto;
  font-weight: 400;
  font-size: 48px;
}

.info {
  margin-top: 40px;
  width: 350px;
  padding: 0;
}

.logo {
  width: 181px;
  color: #979797;
  margin-bottom: 10px;
}

.input {
  width: 350px;
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #606060;
  margin-bottom: 10px;
  color: white !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.7; /* Firefox */
}

.input input {
  font-weight: 500;
  color: white !important;
}

.input:hover, .input:focus {
  border-bottom: 1px solid #CF3131 !important;
  color: var(--white);
}

.submit {
  width: 350px;
  background-color: var(--loginbutton);
  border-color: var(--loginbutton);
  font-weight: 500;
  color: var(--white) !important;
  border-radius: 40px;
  height: 46px;
  margin-top: 40px;
}

.submit:hover, .submit:focus {
  background-color: var(--loginbuttonhover)  !important;
  border-color: var(--white);
}

.icon {
  color: #CF3131;
  margin-right: 15px;
  margin-left: 10px;
}

.wave1 {
  position: absolute;
  bottom: -10px;
}

.wave2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.powered {
  position: absolute;
  bottom: 15px;
  z-index: 40;
  font-family: Bree;
}

.powered a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.powered span {
  margin-right: 10px;
  display: block;
}

.powered img {
  width: 150px;
}

@media only screen and (max-width: 576px) {
  .form {
    width: 100vw;
    height: 100vh;
    padding: 150px 40px 0 40px;
  }

  .info {
    width: 100%;
  }

  .input, .submit {
    width: 100%;
  }

  .wave1 {
    width: 120%;
  }
  
  .wave2 {
    width: 120%;
  }
}

.Item{
  color: #CF3131;
  font-size: 20px;
  font-weight: 400;
}