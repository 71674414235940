view {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .header h1 {
    color: var(--green);
    font-size: 28px;
    font-family: Bree;
    font-weight: 700;
    margin-bottom: 0;
  }
  
  .header button {
    background-color: var(--green);
    border-color: var(--green);
    color: white;
    font-weight: 500;
    font-size: 12px;
    border-radius: 6px;
    padding: 6.4px 40px;
  }
  
  .input {
    background-color: #FBFBFB;
    border-color: #D2D2D2;
    border-radius: 4px;
    width: 300px;
    margin-right: 20px;
  }
  
  .header button:hover,  .header button:focus{
    background-color: var(--green);
    border-color: var(--green);
    color: white;
  }
  
  .empty {
    background-color: white;
    padding: 25px 40px;
    box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
    border-radius: 6px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .empty span {
    color: var(--darkGray);
    font-size: 16px;
    display: block;
    margin-top: 15px;
  }
  
  .rowWrap {
    padding-bottom: 40px;
  }

  .banner {
    background:white;
    margin: 2em auto;
    padding: 1.8em;
    box-shadow: 0 1px 15px #6464645e;
    width:98%;
  }
  .banneroff {
    background: #F0F0F0;
    margin: 2em auto;
    padding: 1.8em;
    box-shadow: 0 1px 15px #6464645e;
  }

  .bannerPhoto {
    background-color: #ECECEC;
    max-width: 220px;
    min-height: 120px;
    background-size:contain;
    background-repeat: no-repeat;
  }
  .bannerTile h3 {
    color: #3C3C3C;
  }
  .bannerTile span {
    color: #575757;
    font-size:0.8em;
    display: block;
  }
  .bannerActions {
    text-align: center;
  }