.customerInfo {
    color: var(--darkGray);
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .title {
    font-family: Bree;
    font-weight: 700;
    color: var(--darkGray);
    font-size: 20px;
  }
  
  .green {
    color: var(--green);
  }
  
  .clientInfo {
    padding: 10px 20px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 25px;
  }

  .clientInfo .clientDetail {
    display:flex;
    flex-direction: column;
    padding-top: 1.3em;
  }
  
  .label {
    font-weight: 500;
    font-size: 13px;
  }

  .labelComercial {
    color: #CF3131;
  }
  
  .labelClient {
    display: block;
    margin-right: 5px;
  }

  .labelResp {
    color: #32A6DC;
  }
  
  .labelSection {
    font-family: Bree;
    font-weight: 700;
    margin-bottom: 5px;
    display: block;
  }
  .historyWrap {
    padding: 2em;
    font-size: 16px;
  }
  .prevOrder{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .prevOrder .cellstyle {
    padding: 2em auto;
  }

  .totalPrevOrder {
    color:#CF3131;
    width: 20%;
    text-align: right;
  }
  .cellIcon {
    width: 6%;
  }
  .priceWrap {
    padding: 0.3em;
    font-size: 16px;
  }
  .priceTitle {
    font-weight: bold;
  }