.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.tabs{
    width: calc(100% - 5px);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    width: 90%;
}
.ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 20px !important;
}
.ant-tabs-tab {
    width: 15% !important;
}
.title{
    display: flex;
    justify-content: start;
    align-items: flex-end;
}

.title h1{
    margin: 0;
    font-size: 36px;
    color: #3C3C3C;
    font-weight: 500;
}

.title span{
    font-size: 18px;
    color: #32A6DC;
    font-weight: 400;
    margin-bottom: 10px;
}

.paymentMethod{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
  }

.amount{
    color: #32A6DC;
    font-weight: 400;
    font-size: 14px;
}

.actions-order{
    display: flex;
    justify-content: center;
    gap: 5px;
}

.cursor-pointer{
    cursor: pointer;
}

.header_modal{
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem 0 1rem;
}

.status_order{
    background-color: #C5ECFF;
    border: 1px solid #A4CDE1;
    color: #585858;
    font-size: 14px;
    font-weight: 500;
    padding: 0.7rem 2rem;
    border-radius: 6px;
}

.title_order{
    color: #3C3C3C;
    font-size: 30px;
    font-weight: 500;
}

.date_order{
    color: #7D7D7D;
    font-size: 16px;
    font-weight: 400;
}
