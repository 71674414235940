.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.titleWrap {
  display: flex;
  align-items: center;
  width: 100%;
}

.title {
  font-weight: 700;
  font-family: Bree;
  color: var(--green);
  font-size: 30px;
  line-height: 25px;
}

.backWrap {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  border-radius: 100%;
  color: white;
  margin-right: 15px;
}